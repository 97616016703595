module.exports = global =
{
 APP_ADMIN:
  {
			LOGIN_BTN_TXT: 'Login Now',
			LOGIN_SCREEN_IMG: 'login-screen-2.jpg',
			LOGIN_EMAIL_ERR: 'Enter',
			LOGIN_PASS_ERR: 'login-screen-2.jpg',
		},
 APP_ADMIN_FORMS:
  {
			FORM_CAT: 'Category',
		},
	APP_ADMIN_API:
  { 
			URL: 'http://localhost:1234/apis-web-architect/' 
		}
};