import React, { useState,  Suspense, lazy } from 'react';

import { Routes, Route, Link } from 'react-router-dom';
import UseCallbackExample from './PracticeComponents/useCallback-example';

const FormLayout = lazy(() => import('./PracticeComponents/FormLayout'))
const Login 					= lazy(() => import('./AdminPages/Login'))
const Dashboard 	= lazy(() => import('./AdminPages/Dashboard'))

function App() {
	// const [isUserLogin, setisUserLogin] = useState(true);
	// if (isUserLogin) {
	// 	return <Login />
	// }

	return (
		<>
				<Routes>
					<Route path="/" 									element={<React.Suspense fallback={<>...</>}> <FormLayout /> </React.Suspense>} />
					<Route path="/home" 					element={ <HomePage /> } />
					<Route path="/login" 				element={<React.Suspense fallback={<>...</>}> <Login /> </React.Suspense>} />
					<Route path="/dashboard" element={<React.Suspense fallback={<>...</>}> <Dashboard /> </React.Suspense>} />
				</Routes>
		</>
	);
}

const HomePage = () => {
	return (
		<div>
				<h1>Home Page </h1>
				<ul>
					<li><Link to='/login'>Go to Login Page</Link></li>
					<li><Link to='/dashboard'>Go to Dashboard Page</Link></li>
				</ul>
		</div>
	)
};


export default App;
